@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=STIX+Two+Text:wght@400;500;600;700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Lexend", sans-serif;
}

/* Define transition for opacity */
.output-text {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.cropper-point.point-se::before {
  background-color: #FF751E !important
}

.cropper-line {
  background-color: #FF751E !important
}

.cropper-crop-box {
  border-color: #FF751E !important;
  /* Change border color */
}

.cropper-view-box {
  outline: 2px solid #FF751E !important;
  /* Change outline color */
}

.cropper-dashed {
  border-color: #FF751E !important;
  /* Change dashed border color */
}

.cropper-center,
.cropper-face {
  background-color: #FF751E !important;
  /* Change background color for center and face */
}

.cropper-line {
  background-color: #FF751E !important;
  /* Change color of cropper lines */
}

.cropper-point {
  border-color: #FF751E !important;
  /* Change color of cropper points */
  background-color: #FF751E !important;
  /* Change background color of cropper points */
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.cropper-point.point-se {
  bottom: -7px;
  cursor: nwse-resize;
  height: 16px !important;
  opacity: 1;
  right: -8px !important;
  width: 16px !important;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -8px !important;
  top: -7px !important;
  width: 16px !important;
  height: 16px !important;
  opacity: 1;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -8px !important;
  top: -7px !important;
  width: 16px !important;
  height: 16px !important;
  opacity: 1;

}

.cropper-point.point-sw {
  cursor: nwse-resize;
  left: -8px !important;
  bottom: -7px !important;
  width: 16px !important;
  height: 16px !important;
  opacity: 1;
}

/* CSS */
textarea::placeholder {
  color: #191B1F;
}

textarea.focused-placeholder::placeholder {
  color: #AEB3BE;

}


.wisegpt span span {
  opacity: 0;
  animation: move-text 0.1s forwards;
}

.paragraph-style {
  font-weight: 400;
  font-size: 16px;
  color: #191B1F;

}

.list-item-style {
  font-weight: 400;
  font-size: 16px;
  color: #191B1F;

}


.wisegpt_response p,
ul,
li {
  font-weight: 400;
  font-size: 16px;
  color: #191B1F;
  background-color: transparent;
}

.wisegpt_response html body {
  background-color: transparent;
}

.wisegpt_response ul {
  padding-inline-start: 20px
}

.custom-list {
  display: block;
  list-style-type: disc;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  unicode-bidi: isolate;

}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }

  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

p {
  margin: 0 !important;
}

.chip-category:hover {
  .category_name {
    color: #FF751E;
  }
}